import React from "react";
import PropTypes from "prop-types";
import { MDBox, DataTable } from "components";
import { ActionsCell, SidCell } from "utils";
import { useMaterialUIController } from "context";

function RecordsGrid({ records, onViewDetails }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const columns = [
    {
      Header: "Unique ID",
      accessor: "sid",
      Cell: (props) => <SidCell {...props} darkMode={darkMode} />,
    },
    { Header: "Name", accessor: "name" },
    {
      Header: "Provider",
      accessor: "provider",
      Cell: ({ value }) => value.name,
    },
    { Header: "Model", accessor: "model", Cell: ({ value }) => value.model },
    { Header: "Created", accessor: "created" },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: (props) => <ActionsCell {...props} darkMode={darkMode} onViewDetails={onViewDetails} />,
      width: 150,
    },
  ];

  const rows = Array.isArray(records)
    ? records.map((record) => ({
        sid: record.sid,
        name: record.name,
        provider: { name: record.model.provider.name },
        model: { model: record.model.model },
        created: record.created,
        api_key: record.api_key,
      }))
    : [];

  return (
    <MDBox>
      <DataTable
        table={{ columns, rows }}
        showTotalEntries={false}
        isSorted={true}
        noEndBorder={true}
        entriesPerPage={{ defaultValue: 10 }}
        canSearch={true}
        pagination={{ variant: "contained" }}
      />
    </MDBox>
  );
}

RecordsGrid.propTypes = {
  records: PropTypes.arrayOf(
    PropTypes.shape({
      sid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      model: PropTypes.object.isRequired,
      created: PropTypes.string.isRequired,
    })
  ).isRequired,
  onViewDetails: PropTypes.func.isRequired,
};

export default RecordsGrid;
