import React, { useState } from "react";
import PropTypes from "prop-types";
import { MDBox, DataTable } from "components";
import { ActionsCell, ApiKeyCell, SidCell } from "utils";
import { useMaterialUIController } from "context";

function RecordsGrid({ records, onViewDetails }) {
  const [showApiKeys, setShowApiKeys] = useState({});
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const toggleApiKeyVisibility = (providerSid) => {
    setShowApiKeys((prev) => ({
      ...prev,
      [providerSid]: !prev[providerSid],
    }));
  };

  const columns = [
    {
      Header: "Unique ID",
      accessor: "sid",
      Cell: (props) => <SidCell {...props} darkMode={darkMode} />,
    },
    { Header: "Name", accessor: "name" },
    { Header: "Type", accessor: "type" },
    { Header: "Created", accessor: "created" },
    {
      Header: "API Key",
      accessor: "api_key",
      Cell: (props) => (
        <ApiKeyCell
          {...props}
          toggleApiKeyVisibility={toggleApiKeyVisibility}
          showApiKeys={showApiKeys}
          darkMode={darkMode}
        />
      ),
      width: 360,
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: (props) => <ActionsCell {...props} darkMode={darkMode} onViewDetails={onViewDetails} />,
      width: 150,
    },
  ];

  const rows = Array.isArray(records)
    ? records.map((record) => ({
        sid: record.sid || "unknown-sid",
        name: record.name,
        type: record.type,
        created: record.created,
        api_key: record.api_key,
      }))
    : [];

  return (
    <MDBox>
      <DataTable
        table={{ columns, rows }}
        showTotalEntries={false}
        isSorted={true}
        noEndBorder={true}
        entriesPerPage={{ defaultValue: 10 }}
        canSearch={true}
        pagination={{ variant: "contained" }}
      />
    </MDBox>
  );
}

RecordsGrid.propTypes = {
  records: PropTypes.arrayOf(
    PropTypes.shape({
      sid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      created: PropTypes.string.isRequired,
      api_key: PropTypes.string.isRequired,
    })
  ).isRequired,
  onViewDetails: PropTypes.func.isRequired,
};

export default RecordsGrid;
