import React, { useState, useEffect } from "react";
import { DialogTitle, DialogActions, DialogContent, Icon, Grid, Dialog } from "@mui/material";
import { useUserContext } from "context";
import { MDButton, MDTypography } from "components";
import { lang } from "hooks";
import { replacePlaceholdersWithTranslations } from "utils";
import PropTypes from "prop-types";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";

const AddChatAssistantDialog = ({ open, onClose, onSubmit, btnColor, darkMode }) => {
  const myTheme = darkMode ? themeDark : theme;
  const { chatPromptTemplates } = useUserContext();
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    setSelectedOption(0);
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          backgroundColor: myTheme.palette.background.card,
          color: darkMode ? myTheme.palette.dark.main : myTheme.palette.white.main,
          padding: "5px 20px",
          borderRadius: "16px",
        },
      }}
    >
      <DialogTitle
        sx={{
          color: darkMode ? "lightgrey" : "black",
          fontWeight: "regular",
          fontSize: "20px",
          opacity: 0.8,
          paddingBottom: "30px",
        }}
      >
        <Icon fontSize="medium" style={{ verticalAlign: "sub", marginRight: "3px" }}>
          public
        </Icon>
        {lang("ucwords")("SELECT_PROMPT_TEMPLATE_DIALOG_TITLE")}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {chatPromptTemplates.map((template, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <div
                style={{
                  border: selectedOption === index ? "1px solid grey" : "none",
                  backgroundColor: myTheme.palette.background.default,
                  padding: "20px",
                  textAlign: "center",
                  opacity: 0.8,
                  minWidth: "160px",
                  minHeight: "160px",
                  borderRadius: "16px",
                  cursor: "pointer",
                }}
                onClick={() => handleOptionClick(index)}
              >
                <Icon fontSize="large">{template.icon}</Icon>
                <MDTypography variant="body2">
                  {replacePlaceholdersWithTranslations(template.title, "ucwords")}
                </MDTypography>
              </div>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={() => onSubmit(selectedOption)} color={btnColor} autoFocus>
          Next
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

AddChatAssistantDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  btnColor: PropTypes.string.isRequired,
  darkMode: PropTypes.bool.isRequired,
};

export default AddChatAssistantDialog;
