import React, { createContext, useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import ChatBox from "components/ChatBox";

// Create the context
const ChatContext = createContext();

// Create a provider component
export const ChatProvider = ({ children }) => {
  const [isChatVisible, setChatVisible] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isElHidden, setIsElHidden] = useState(false);

  const openChat = () => {
    if (!isChatVisible) {
      setChatVisible(true);
    }
    console.log("openChat", isChatVisible);
  };

  const closeChat = () => {
    if (isChatVisible) {
      setChatVisible(false);
    }
    console.log("closeChat", isChatVisible);
  };

  const toggleChat = () => {
    console.log("toggleChat", isChatVisible);
    setChatVisible(!isChatVisible);
  };

  const openChatEl = () => {
    if (!isChatVisible) {
      setChatVisible(true);
      setIsClicked(true);
      return;
    }
    if (!isClicked) {
      document.querySelector(".rcw-launcher").click();
      setIsClicked(true);
    }
    const chatBox = document.querySelector(".rcw-widget-container");
    chatBox.style.display = "";
    console.log("openChatEl", isChatVisible, isClicked);
  };

  const closeChatEl = () => {
    if (!isChatVisible) {
      return;
    }
    const chatBox = document.querySelector(".rcw-widget-container");
    chatBox.style.display = "none";
    setIsClicked(false);
    console.log("closeChatEl", isChatVisible, isClicked);
  };

  const toggleChatEl = () => {
    if (!isChatVisible) {
      toggleChat();
      return;
    }
    console.log("toggleChatEl", isChatVisible);
    const chatBox = document.querySelector(".rcw-widget-container");
    if (!isElHidden) {
      chatBox.style.display = "none"; // Hide the element
    } else {
      chatBox.style.display = ""; // Remove the inline display property
    }
    setIsElHidden(!isElHidden);
  };

  useEffect(() => {
    console.log("useEffect.isChatVisible", isChatVisible, isClicked);
    if (isChatVisible) {
      document.querySelector(".rcw-launcher").click();
    }
  }, [isChatVisible]);

  return (
    <ChatContext.Provider
      value={{
        isChatVisible,
        toggleChat,
        isClicked,
        setIsClicked,
        isChatVisible,
        setChatVisible,
        closeChat,
        openChat,
        toggleChatEl,
        openChatEl,
        closeChatEl,
      }}
    >
      {children}
      {isChatVisible && <ChatBox />} {/* Display the chat box when visible */}
    </ChatContext.Provider>
  );
};

// Define prop types for ChatProvider
ChatProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Custom hook to use the chat context
export const useChat = () => useContext(ChatContext);
