import React, { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useMediaQuery } from "react-responsive";
import PropTypes from "prop-types";
import { lang } from "hooks";
import { useMaterialUIController } from "context";
import { Grid, Card, CardContent, Icon } from "@mui/material";
import { RecordForm, TipsPanel, Embed } from "./components";
import { MDTypography, CustomHidden, DangerZone, MDBox, Panel } from "components";

const RecordDetails = ({ record, onDelete, onEdit, promptTemplate = null }) => {
  const [controller] = useMaterialUIController();
  const { darkMode, sidenavColor: mainColor } = controller;
  const [activeTab, setActiveTab] = useState(0);
  const isLgOrUp = useMediaQuery({ query: "(min-width: 992px)" });
  const firstGridRef = useRef(null);
  const [firstGridHeight, setFirstGridHeight] = useState(0);
  const [defectHeight, setDefectHeight] = useState(55);
  const tabPanels = [
    { id: 0, isPermanent: true },
    { id: 1, isPermanent: record ? true : false },
    { id: 2, isPermanent: false },
    { id: 3, isPermanent: false },
  ];

  const iframe_address = `https://embed.${process.env.REACT_APP_MAIN_DOMAIN}`;
  const iframe_theme = darkMode ? "dark" : "light";

  /*useEffect(() => {
    if (firstGridRef.current) {
      setFirstGridHeight(firstGridRef.current.clientHeight - 55);
    }
  }, []);*/

  useEffect(() => {
    if (isLgOrUp) {
      setFirstGridHeight(firstGridRef.current.clientHeight - defectHeight);
      setDefectHeight(55);
      const currentPanel = tabPanels.find((panel) => panel.id === activeTab);
      if (currentPanel && !currentPanel.isPermanent) {
        setActiveTab(0);
      }
    }
  }, [isLgOrUp, activeTab]);

  return (
    <Tabs
      forceRenderTabPanel
      selectedIndex={activeTab}
      onSelect={(index) => setActiveTab(index)}
      selectedTabClassName={
        darkMode ? "react-tabs-dark__tab--selected" : "react-tabs-dark__tab--selected"
      }
    >
      <TabList>
        <Tab>
          <MDTypography variant="h6" fontWeight="regular" color="inherit">
            <Icon fontSize="small">assignment_outlined</Icon>{" "}
            <CustomHidden only={["xs"]}>{lang("ucfirst")("G_DETAILS")}</CustomHidden>
          </MDTypography>
        </Tab>
        {record?.embed_sid && (
          <Tab>
            <MDTypography variant="h6" fontWeight="regular" color="inherit">
              <Icon fontSize="small">code</Icon>{" "}
              <CustomHidden only={["xs"]}>{lang("ucfirst")("G_EMBED")}</CustomHidden>
            </MDTypography>
          </Tab>
        )}
        <CustomHidden only={["lg", "xl", "xxl"]}>
          <Tab>
            <MDTypography variant="h6" fontWeight="regular" color="inherit">
              <Icon fontSize="small">help_outlined</Icon>{" "}
              <CustomHidden only={["xs"]}>{lang("ucfirst")("G_HELP")}</CustomHidden>
            </MDTypography>
          </Tab>
          {record?.embed_sid && (
            <Tab>
              <MDTypography variant="h6" fontWeight="regular" color="inherit">
                <Icon fontSize="small">chat_bubble</Icon>{" "}
                <CustomHidden only={["xs"]}>{lang("ucfirst")("G_CHAT")}</CustomHidden>
              </MDTypography>
            </Tab>
          )}
        </CustomHidden>
      </TabList>
      <Grid container justifyContent="center" spacing={2} item xs={12}>
        <Grid item xs={12}>
          <Card
            sx={{
              borderRadius: "16px",
              height: isLgOrUp ? (darkMode ? "78vh" : "77.4vh") : "auto",
              minHeight: isLgOrUp
                ? record
                  ? firstGridHeight + 260
                  : firstGridHeight + 90
                : "auto",
            }}
          >
            <CardContent>
              <MDBox mt={2}>
                <TabPanel>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6} ref={firstGridRef}>
                      <RecordForm
                        record={record}
                        onEdit={onEdit}
                        mainColor={mainColor}
                        darkMode={darkMode}
                        promptTemplate={promptTemplate}
                      />
                    </Grid>
                    <CustomHidden only={["xs", "sm", "md"]}>
                      <Grid
                        item
                        xs={12}
                        lg={6}
                        sx={{
                          minHeight: firstGridHeight,
                        }}
                      >
                        <TipsPanel
                          darkMode={darkMode}
                          firstGridHeight={firstGridHeight}
                          embed_sid={record?.embed_sid}
                        />
                      </Grid>
                    </CustomHidden>
                    {record && (
                      <DangerZone
                        record={record}
                        onDelete={onDelete}
                        deleteText={lang("ucfirst")("CHAT_ASSISTANTS_DELETE_TEXT")}
                      />
                    )}
                  </Grid>
                </TabPanel>
                {record?.embed_sid && (
                  <TabPanel>
                    <Embed embed_sid={record?.embed_sid} />
                  </TabPanel>
                )}
                <TabPanel>
                  <Grid item xs={12} lg={6}>
                    <TipsPanel darkMode={darkMode} firstGridHeight="100%" />
                  </Grid>
                </TabPanel>
                {record?.embed_sid && (
                  <TabPanel>
                    <Grid container style={{ height: "70vh", overflow: "hidden" }}>
                      <Grid
                        item
                        xs={12}
                        lg={6}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flexGrow: 1,
                          height: "100%", // Ensure the Grid takes the full height
                          overflow: "hidden", // Prevent scrolling of the parent Grid
                        }}
                      >
                        <Panel
                          darkMode={darkMode}
                          style={{
                            flexGrow: 1,
                            display: "flex",
                            flexDirection: "column",
                            height: "100%", // Panel takes full height of the Grid
                          }}
                        >
                          <CardContent
                            sx={{
                              p: 0,
                              m: 0,
                              flexGrow: 1,
                              paddingBottom: "0px !important",
                              height: "100%", // Ensure CardContent takes the full height
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <MDBox sx={{ flexGrow: 1, p: 0, m: 0, mt: 0, height: "100%" }}>
                              {record?.embed_sid ? (
                                <div
                                  style={{ position: "relative", height: "100%", width: "100%" }}
                                >
                                  <a
                                    href={`${iframe_address}/chat/${
                                      record?.embed_sid
                                    }?theme=${iframe_theme}&placeholder_text=${lang("ucfirst")(
                                      "CONVERSTION_TEST_INPUT_PLACEHOLDER_TEXT"
                                    )}&start_message=${lang("ucfirst")(
                                      "CONVERSTION_TEST_START_MESSAGE"
                                    )}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="hover-link"
                                    style={{
                                      position: "absolute",
                                      top: 5,
                                      right: "45%",
                                      padding: "2px 10px",
                                      borderRadius: "16px",
                                      textDecoration: "none",
                                      color: darkMode ? "lightgrey" : "white",
                                      transition: "visibility 0.3s ease, opacity 0.3s ease",
                                      backgroundColor: "black",
                                      fontSize: "12px",
                                    }}
                                    onMouseEnter={(e) =>
                                      (e.currentTarget.style.backgroundColor = "#2C2C2C")
                                    }
                                    onMouseLeave={(e) =>
                                      (e.currentTarget.style.backgroundColor = "black")
                                    }
                                  >
                                    {lang("ucfirst")("G_NEW_WINDOW")}
                                  </a>

                                  <iframe
                                    src={`${iframe_address}/iframe/${
                                      record?.embed_sid
                                    }?theme=${iframe_theme}&placeholder_text=${lang("ucfirst")(
                                      "CONVERSTION_TEST_INPUT_PLACEHOLDER_TEXT"
                                    )}&start_message=${lang("ucfirst")(
                                      "CONVERSTION_TEST_START_MESSAGE"
                                    )}`}
                                    allow="clipboard-write; clipboard-read;"
                                    width="100%"
                                    height="100%" // Ensures iframe takes up all available height
                                    style={{
                                      border: "none",
                                      display: "block",
                                      margin: 0,
                                      padding: 0,
                                      flexGrow: 1,
                                    }}
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    right: "0",
                                    bottom: "0",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    opacity: 0.5,
                                    color: darkMode ? "lightgrey" : "white",
                                    backgroundColor: "rgba(0, 0, 0, 0.2)", // Semi-transparent background
                                    pointerEvents: "none", // Prevents overlay from blocking interactions with the iframe
                                  }}
                                >
                                  <span>{lang("ucfirst")("CONVERSATION_TEST_SAVE_DATA_MSG")}</span>
                                </div>
                              )}
                            </MDBox>
                          </CardContent>
                        </Panel>
                      </Grid>
                    </Grid>
                  </TabPanel>
                )}
              </MDBox>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Tabs>
  );
};

// Define PropTypes
RecordDetails.propTypes = {
  record: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    sid: PropTypes.string,
    embed_sid: PropTypes.string,
  }),
  onDelete: PropTypes.shape({
    action: PropTypes.func.isRequired,
    callback: PropTypes.func,
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  promptTemplate: PropTypes.number,
};

export default RecordDetails;
