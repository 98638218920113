import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { lang } from "hooks";
import { CardContent, Icon } from "@mui/material";
import { MDTypography, MDBox, CustomHidden } from "components";
import { Panel } from "components";
import PropTypes from "prop-types";

const TipsPanel = ({ darkMode, firstGridHeight = 200, embed_sid = null }) => {
  const [activeTab, setActiveTab] = useState(0);

  const iframe_address = `https://embed.${process.env.REACT_APP_MAIN_DOMAIN}`;
  const iframe_theme = darkMode ? "dark" : "light";

  return (
    <Tabs
      forceRenderTabPanel
      selectedIndex={activeTab}
      onSelect={(index) => setActiveTab(index)}
      selectedTabClassName={
        darkMode ? "react-tabs-dark__tab--selected" : "react-tabs-dark__tab--selected"
      }
    >
      <CustomHidden only={["xs", "sm", "md"]}>
        <TabList>
          <Tab>
            <MDTypography variant="h6" fontWeight="regular" color="inherit">
              <Icon fontSize="small">help_outlined</Icon> {lang("ucfirst")("G_HELP")}
            </MDTypography>
          </Tab>
          {embed_sid && (
            <Tab>
              <MDTypography variant="h6" fontWeight="regular" color="inherit">
                <Icon fontSize="small">chat</Icon> {lang("ucfirst")("G_CHAT")}
              </MDTypography>
            </Tab>
          )}
        </TabList>
      </CustomHidden>
      <Panel
        darkMode={darkMode}
        sx={{
          minHeight: firstGridHeight,
          maxHeight: firstGridHeight,
          overflow: "auto",
          scrollbarWidth: "thin",
        }}
      >
        <CardContent sx={{ p: 0, m: 0, paddingBottom: "0px !important" }}>
          <TabPanel>
            <MDBox mt={1} sx={{ p: 2, color: "grey" }}>
              {lang("span", {
                "@opacity": darkMode ? "0.6" : "0.8",
                "@color": darkMode ? "lightgrey" : "grey",
                "@files_link": `${process.env.REACT_APP_BASE_PATH}/settings/files`,
              })("CHAT_ASSISTANTS_TIPS")}
            </MDBox>
          </TabPanel>
          {embed_sid && (
            <TabPanel>
              <MDBox
                sx={{
                  flex: 1,
                  p: 0,
                  m: 0,
                  mt: 0,
                  position: "relative",
                  "&:hover .hover-link": {
                    visibility: "visible",
                    opacity: 1,
                  },
                }}
              >
                <a
                  href={`${iframe_address}/chat/${embed_sid}?theme=${iframe_theme}&placeholder_text=${lang(
                    "ucfirst"
                  )("CONVERSTION_TEST_INPUT_PLACEHOLDER_TEXT")}&start_message=${lang("ucfirst")(
                    "CONVERSTION_TEST_START_MESSAGE"
                  )}`}
                  target="_blank"
                  rel="noreferrer"
                  className="hover-link"
                  style={{
                    position: "absolute",
                    top: 5,
                    right: "45%",
                    padding: "2px 10px",
                    borderRadius: "16px",
                    textDecoration: "none",
                    color: darkMode ? "lightgrey" : "white",
                    transition: "visibility 0.3s ease, opacity 0.3s ease",
                    backgroundColor: "black",
                    fontSize: "12px",
                  }}
                  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#2C2C2C")}
                  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "black")}
                >
                  {lang("ucfirst")("G_NEW_WINDOW")}
                </a>
                {embed_sid ? (
                  <iframe
                    src={`${iframe_address}/iframe/${embed_sid}?theme=${iframe_theme}&placeholder_text=${lang(
                      "ucfirst"
                    )("CONVERSTION_TEST_INPUT_PLACEHOLDER_TEXT")}&start_message=${lang("ucfirst")(
                      "CONVERSTION_TEST_START_MESSAGE"
                    )}`}
                    allow="clipboard-write; clipboard-read;"
                    width="100%"
                    style={{
                      minHeight: "445px",
                      border: "none",
                      display: "block",
                      margin: 0,
                      padding: 0,
                      flexGrow: 1,
                    }}
                  />
                ) : (
                  <div
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      right: "0",
                      bottom: "0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      opacity: 0.5,
                      color: darkMode ? "lightgrey" : "black", // Change color as needed
                      backgroundColor: "rgba(0, 0, 0, 0.2)", // Semi-transparent background
                      pointerEvents: "none", // Prevents overlay from blocking interactions with the iframe
                    }}
                  >
                    <span>{lang("ucfirst")("CONVERSATION_TEST_SAVE_DATA_MSG")}</span>
                  </div>
                )}
              </MDBox>
            </TabPanel>
          )}
        </CardContent>
      </Panel>
    </Tabs>
  );
};

TipsPanel.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  firstGridHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  embed_sid: PropTypes.string,
};

export default TipsPanel;
