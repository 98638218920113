export function getUiConfig(key = null, defaultValue = null) {
  try {
    const uiConfig = localStorage.getItem("ui_config");
    const configObject = uiConfig ? JSON.parse(uiConfig) : null;

    if (key) {
      const test = configObject ? (configObject[key] ?? defaultValue) : defaultValue;
      console.log("DDDDDDDDDDDDD", key, test);
      return test;
    }

    return configObject;
  } catch (error) {
    console.error("Error retrieving ui_config from localStorage:", error);
    return null;
  }
}

export function setUiConfig(newValues) {
  try {
    const existingConfig = getUiConfig() || {};
    const updatedConfig = { ...existingConfig, ...newValues };
    localStorage.setItem("ui_config", JSON.stringify(updatedConfig));
  } catch (error) {
    console.error("Error setting ui_config in localStorage:", error);
  }
}
