import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { MDButton, MDBox } from "components";
import { FormValuesContext } from "context";
import { lang } from "hooks";
import { providersConfigDefaults } from "configDefaults";
import { TextareaAutosize, Icon } from "@mui/material";
import { MDTypography, LoadingOverlay } from "components";
import { useTestProviderServices } from "services";

const TestConnection = ({ darkMode, sidenavColor = null }) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [requestOutput, setRequestOutput] = useState("");
  const [connectionMsg, setConnectionMsg] = useState(
    lang("ucfirst")("PROVIDER_TEST_CONNECTION_START")
  );
  const { formValues } = useContext(FormValuesContext);
  const { testConnection } = useTestProviderServices();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!formValues.source || !formValues.type || !formValues.address || !formValues.api_key) {
      setIsButtonDisabled(true);
      setRequestOutput("");
    } else {
      setIsButtonDisabled(false);
      setRequestOutput("");
      setConnectionMsg(lang("ucfirst")("PROVIDER_TEST_CONNECTION_START"));
    }
  }, [formValues]);

  const handleTest = async () => {
    setIsLoading(true);
    setConnectionMsg("");
    setRequestOutput("");
    if (formValues.type == "model") {
      let model = "";
      let defaultConfigObj = {};
      if (!formValues.default_config) {
        model = providersConfigDefaults["model"][formValues.type]["model"];
        if (!model || model == "please add your model here") {
          setConnectionMsg(lang("ucfirst")("TEST_CONNECTION_PROVIDER_NO_MODEL"));
          setIsLoading(false);
          return;
        }
      } else {
        try {
          defaultConfigObj = JSON.parse(formValues.default_config);
        } catch (error) {
          setConnectionMsg(lang("ucfirst")("PROVIDERS_INVALID_CONFIG"));
          setIsLoading(false);
          return;
        }
        if (!defaultConfigObj.hasOwnProperty("model")) {
          //console.log("MISSING CONFIG MODEL");
          model = providersConfigDefaults["model"][formValues.source]["model"];
          if (!model || model == "please add your model here") {
            setConnectionMsg(lang("ucfirst")("TEST_CONNECTION_PROVIDER_NO_MODEL"));
            setIsLoading(false);
            return;
          }
          //console.log("USING DEFAULT CONFIG MODEL", model);
        } else {
          model = defaultConfigObj["model"];
          delete defaultConfigObj["model"];
          //console.log("USING CONFIG MODEL", model);
        }
      }
      const result = await testConnection({
        type: formValues.type,
        source: formValues.source,
        api_key: formValues.api_key,
        address: formValues.address,
        model: model,
        args: defaultConfigObj,
      });
      const icon = result.status == 200 ? "🟢" : "🔴";
      const elapsedTimeHeader = result.headers.get("x-elapsed-time");
      const formattedElapsedTime = parseFloat(elapsedTimeHeader).toFixed(2);
      let output = "";
      //output += icon + "Output:\n\n";
      //output += "\n\n";
      output += icon + " Status code: " + result.status + "\n\n";
      output += icon + " Time elapsed: " + formattedElapsedTime + "\n\n";
      output += icon + " Response: " + result.data.message;
      setRequestOutput(output);
      setIsLoading(false);
    }
  };

  return (
    <MDBox
      mt={2}
      sx={{
        //height: "100%",
        minHeight: "459px",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {!isButtonDisabled && (
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            opacity: 0.5,
            color: darkMode ? "lightgrey" : "black",
            pointerEvents: "none",
          }}
        >
          <span>{connectionMsg}</span>
        </div>
      )}
      <MDBox mb={1}>
        <MDTypography variant="h5" color="error" fontWeight="regular" sx={{ opacity: 0.8 }}>
          <Icon fontSize="medium" sx={{ transform: "translateY(4px)" }}>
            cloud_upload_outlined
          </Icon>{" "}
          {lang("ucfirst")("G_TEST_CONNECTION")}
        </MDTypography>
      </MDBox>
      <MDBox>
        <LoadingOverlay
          isLoading={isLoading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(255, 0, 0, 0.5)",
            }),
          }}
        >
          <TextareaAutosize
            minRows={11}
            name="test_output"
            value={requestOutput}
            style={{
              borderColor: darkMode ? "rgba(255, 255, 255, 0.5)" : "rgb(12 10 10 / 12%)",
              outline: "none",
              width: "100%",
              height: "398px !important",
              padding: "12px",
              fontSize: "0.9rem",
              resize: "none",
              overflow: "auto",
              //minHeight: "80px",
              color: darkMode ? "white" : "black",
              backgroundColor: "transparent",
              borderRadius: "8px",
              opacity: 0.8,
              marginBottom: "25px",
            }}
            readOnly
          />
        </LoadingOverlay>
      </MDBox>
      <MDBox sx={{ flexGrow: 1 }} />
      <MDButton
        type="button"
        color={sidenavColor}
        variant="gradient"
        fullWidth
        onClick={handleTest} // Add onClick handler to the button
        disabled={isButtonDisabled} // Disable the button based on state
      >
        {lang("ucfirst")("G_TEST_CONNECTION")}
      </MDButton>
      {isButtonDisabled && (
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            opacity: 0.5,
            color: darkMode ? "lightgrey" : "black",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            pointerEvents: "none",
          }}
        >
          <span style={{ padding: "50px" }}>
            {lang("ucfirst")("PROVIDER_TEST_CONNECTION_MISSING_VALUES")}
          </span>
        </div>
      )}
    </MDBox>
  );
};

// Prop types validation
TestConnection.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  sidenavColor: PropTypes.string,
};

export default TestConnection;
