// src/customConsole.js

// Save original console methods
const originalConsoleLog = console.log;
const originalConsoleError = console.error;
const originalConsoleWarn = console.warn;

// Create a wrapper for console.log
console.log = function (...args) {
  if (process.env.NODE_ENV !== "production" || process.env.REACT_APP_DEBUG_BUILT) {
    originalConsoleLog.apply(console, args);
  }
};

// Create a wrapper for console.error
console.error = function (...args) {
  if (process.env.NODE_ENV !== "production" || process.env.REACT_APP_DEBUG_BUILT) {
    originalConsoleError.apply(console, args);
  }
};

// Create a wrapper for console.warn
console.warn = function (...args) {
  if (process.env.NODE_ENV !== "production" || process.env.REACT_APP_DEBUG_BUILT) {
    originalConsoleWarn.apply(console, args);
  }
};
