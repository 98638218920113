import React, { useState, useEffect, useContext } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "!style-loader!css-loader!react-input-range/lib/css/index.css";
import PropTypes from "prop-types";
import { useSnackbar, lang, useSelectToggle } from "hooks";
import { useGetProviders } from "services/providerService";
import {
  Icon,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import {
  MDBox,
  MDTypography,
  MDInput,
  MDButton,
  Panel,
  CustomHidden,
  EnlargeTextareaDialog,
} from "components";
import {
  inputReadOnlySX,
  areRequiredFieldsFilled,
  hasChanges,
  formatErrorMsg,
  HelpToolTip,
  CustomBadge,
  inputErrorSX,
} from "utils";
import { providersConfigDefaults } from "configDefaults";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FormValuesContext } from "context";

const RecordForm = ({ record, onEdit, darkMode, mainColor, providerType = null }) => {
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  const { addProvider, editProvider } = useGetProviders();
  const [formErrors, setFormErrors] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [readOnlyFields, setReadOnlyFields] = useState([]);
  const [showApiKey, setShowApiKey] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "New Provider",
    type: providerType ? providerType : "",
    source: "",
    address: "",
    api_key: "",
    default_config: "",
  });
  const [requiredFields] = useState(["name", "type", "source", "address", "api_key"]);
  const { selectOpen, handleIconClick, closeSelect, openSelect } = useSelectToggle();
  const [activeTab, setActiveTab] = useState(0);
  const { formValues: providerFormValues, handleChange: handleProviderFormValuesChange } =
    useContext(FormValuesContext);
  const [openTextareaFullscreen, setOpenTextareaFullscreen] = useState(false);

  const onCloseTextareaFullscreen = () => {
    setOpenTextareaFullscreen(false);
  };

  const options = {
    model: [
      { value: "openai", label: "OpenAI" },
      { value: "anthropic", label: "Anthropic" },
      { value: "cohere", label: "Cohere" },
      { value: "ollama", label: "ollama" },
      //{ value: "custom", label: "custom" },
    ],
    transcriber: [
      { value: "openai", label: "OpenAI" },
      //{ value: "custom", label: "Custom" },
    ],
  };

  useEffect(() => {
    handleProviderFormValuesChange(formValues);
  }, [formValues]);

  useEffect(() => {
    if (record) {
      const { name, type, source, address, api_key } = record;
      const default_config = JSON.stringify(record.default_config, null, 2);
      setFormValues({ name, type, source, address, api_key, default_config });
      setInitialValues({ name, type, source, address, api_key, default_config });
      setReadOnlyFields(["type"]);
    }
  }, [record]);

  const handleInputChange = (event) => {
    console.log(providerFormValues);
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setFormErrors((prevErrors) => prevErrors.filter((errorField) => errorField !== name));
    if (name == "type") {
      handleTypeChange();
    } else if (name == "source") {
      handleSourceChange(value);
    }
  };

  const handleTypeChange = () => {
    setFormValues((prevValues) => ({
      ...prevValues,
      source: "",
      address: "",
      default_config: "",
    }));
  };

  const handleSourceChange = (value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      address:
        providersConfigDefaults["addresses"]?.[value] ||
        providersConfigDefaults["addresses"]?.default ||
        "",
      default_config:
        JSON.stringify(providersConfigDefaults[formValues.type]?.[value], null, 2) ||
        JSON.stringify(providersConfigDefaults[formValues.type]?.default, null, 2) ||
        "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, type, source, address, api_key, default_config } = formValues;
    let errorMsg = "";
    let defaultConfigObj = {};
    if (default_config) {
      try {
        defaultConfigObj = JSON.parse(default_config);
      } catch (error) {
        showSnackbar("error", lang("ucfirst")("PROVIDERS_INVALID_CONFIG"));
        return;
      }
    }
    try {
      if (record) {
        const response = await editProvider({
          name,
          type,
          source,
          address,
          api_key,
          default_config: defaultConfigObj,
          sid: record.sid,
        });
        if (response.status_code === 200) {
          onEdit(false);
        } else if ((errorMsg = formatErrorMsg(response.data))) {
          setFormErrors(Object.keys(response.data.details));
          showSnackbar("error", errorMsg);
        } else {
          showSnackbar("error", lang("ucfirst")("G_FORM_ERROR"));
        }
      } else {
        const response = await addProvider({
          name,
          type,
          source,
          address,
          api_key,
          default_config: defaultConfigObj,
        });
        if (response.status_code === 200) {
          onEdit(true);
        } else if ((errorMsg = formatErrorMsg(response.data))) {
          setFormErrors(Object.keys(response.data.details));
          showSnackbar("error", errorMsg);
        } else {
          showSnackbar("error", lang("ucfirst")("G_FORM_ERROR"));
        }
      }
    } catch (error) {
      console.error("Error handling record:", error);
      showSnackbar("error", lang("ucfirst")("G_FORM_ERROR"));
    }
  };

  const [copied, setCopied] = useState(false);
  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };

  const defaultConfigElement = (height = "150px !important") => {
    return (
      <TextareaAutosize
        minRows={2}
        maxRows={2}
        name="default_config"
        value={formValues.default_config}
        placeholder='Ex: { "model": "gpt-4o" }'
        onChange={handleInputChange}
        disabled={formValues.source ? false : true}
        style={{
          borderColor: darkMode ? "rgba(255, 255, 255, 0.5)" : "rgb(12 10 10 / 12%)",
          outline: "none",
          width: "100%",
          height: height,
          padding: "12px",
          fontSize: "0.9rem",
          resize: "none",
          overflow: "auto",
          minHeight: "80px",
          color: darkMode ? "white" : "black",
          backgroundColor: "transparent",
          borderRadius: "8px",
          opacity: 0.8,
        }}
      />
    );
  };

  return (
    <Tabs
      forceRenderTabPanel
      selectedIndex={activeTab}
      onSelect={(index) => setActiveTab(index)}
      selectedTabClassName={
        darkMode ? "react-tabs-dark__tab--selected" : "react-tabs-dark__tab--selected"
      }
    >
      <EnlargeTextareaDialog
        open={openTextareaFullscreen}
        onClose={onCloseTextareaFullscreen}
        darkMode={darkMode}
        element={defaultConfigElement("40vh")}
      />
      <TabList>
        <CustomHidden only={["xs", "sm", "md"]}>
          <Tab>
            <MDTypography variant="h6" fontWeight="regular" color="inherit">
              <Icon fontSize="small">assignment_outlined</Icon> {lang("ucfirst")("G_DETAILS")}
            </MDTypography>
          </Tab>
        </CustomHidden>
      </TabList>
      <Panel darkMode={darkMode}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <MDBox component="form" mt={1} onSubmit={handleSubmit}>
            {SnackbarComponent}
            <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <MDTypography variant="h5" color="error" fontWeight="regular" sx={{ opacity: 0.8 }}>
                <Icon fontSize="medium" sx={{ transform: "translateY(4px)" }}>
                  {record ? "edit" : "add_circle_outline"}
                </Icon>{" "}
                {record
                  ? lang("ucwords")("PROVIDERS_EDIT_RECORD_TITLE")
                  : lang("ucwords")("PROVIDERS_NEW_RECORD_TITLE")}
              </MDTypography>
              {record && <CustomBadge text={record.sid} lang={lang} darkMode={darkMode} />}
            </MDBox>
            <TabPanel>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <MDBox mb={0} mt={2} sx={{ opacity: 0.8 }}>
                    <MDInput
                      fullWidth
                      name="name"
                      label={`${lang("ucwords")("G_NAME")}*`}
                      value={formValues.name}
                      onChange={handleInputChange}
                      error={formErrors.includes("name")}
                      InputProps={{
                        endAdornment:
                          !formErrors.includes("name") &&
                          HelpToolTip(lang()("G_FORM_TIP_FIELD_NAME"), darkMode),
                      }}
                      sx={inputErrorSX(darkMode, formErrors.includes("name"))}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                  {record ? (
                    <MDBox
                      mb={1}
                      mt={0}
                      sx={{ opacity: darkMode ? 0.4 : 0.6, mt: { xs: 0, sm: 0, md: 0, lg: 2 } }}
                    >
                      <MDInput
                        fullWidth
                        name="type"
                        label={`${lang("ucwords")("G_TYPE")}*`}
                        value={formValues.type}
                        onChange={handleInputChange}
                        InputProps={{
                          readOnly: readOnlyFields.includes("type"),
                          endAdornment: HelpToolTip(lang()("PROVIDERS_FORM_TIP_TYPE"), darkMode),
                        }}
                        sx={inputReadOnlySX(darkMode)}
                      />
                    </MDBox>
                  ) : (
                    <MDBox mb={1} sx={{ opacity: 0.8, mt: { xs: 0, sm: 0, md: 0, lg: 2 } }}>
                      <FormControl fullWidth>
                        <InputLabel id="provider-type-label">Type*</InputLabel>
                        <Select
                          name="type"
                          labelId="provider-type-label"
                          value={formValues.type}
                          onChange={handleInputChange}
                          open={selectOpen["type"] || false}
                          onClose={() => closeSelect("type")}
                          onOpen={() => openSelect("type")}
                          variant="outlined"
                          margin="none"
                          sx={{ minHeight: 43 }}
                          IconComponent={() =>
                            HelpToolTip(
                              lang()("PROVIDERS_FORM_TIP_PROVIDER"),
                              darkMode,
                              "select",
                              (event) => handleIconClick("type", event)
                            )
                          }
                        >
                          {/*<MenuItem value="transcriber">Transcriber</MenuItem>*/}
                          <MenuItem value="model">Model</MenuItem>
                        </Select>
                      </FormControl>
                    </MDBox>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <MDBox mt={1} sx={{ opacity: 0.8, mb: { xs: 0, sm: 0, md: 0, lg: 1 } }}>
                    <FormControl fullWidth>
                      <InputLabel id="source-type-label">Source*</InputLabel>
                      <Select
                        name="source"
                        labelId="source-type-label"
                        value={formValues.source}
                        onChange={handleInputChange}
                        open={selectOpen["source"] || false}
                        onClose={() => closeSelect("source")}
                        onOpen={() => openSelect("source")}
                        variant="outlined"
                        margin="none"
                        sx={{ minHeight: 43 }}
                        disabled={formValues.type ? false : true}
                        IconComponent={() =>
                          HelpToolTip(
                            lang()("PROVIDERS_FORM_TIP_SOURCE"),
                            darkMode,
                            "select",
                            (event) => handleIconClick("source", event)
                          )
                        }
                      >
                        {options[formValues.type]?.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </MDBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MDBox mb={1} sx={{ opacity: 0.8, mt: { xs: 0, sm: 0, md: 0, lg: 1 } }}>
                    <MDInput
                      name="address"
                      fullWidth
                      label="Address*"
                      value={formValues.address}
                      onChange={handleInputChange}
                      error={formErrors.includes("address")}
                      //disabled={formValues.source ? false : true}
                      InputProps={{
                        readOnly: formValues.source ? false : true,
                        endAdornment:
                          !formErrors.includes("address") &&
                          HelpToolTip(lang()("PROVIDERS_FORM_TIP_ADDRESS"), darkMode),
                      }}
                      sx={formValues.source ? {} : inputReadOnlySX(darkMode)}
                    />
                  </MDBox>
                </Grid>
              </Grid>

              <MDBox mb={1} mt={1} sx={{ opacity: 0.8 }}>
                <MDInput
                  name="api_key"
                  fullWidth
                  label="API Key*"
                  type={showApiKey ? "text" : "password"}
                  value={formValues.api_key}
                  onChange={handleInputChange}
                  InputProps={{
                    readOnly: formValues.source ? false : true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <CopyToClipboard text={formValues.api_key} onCopy={handleCopy}>
                          <Tooltip
                            title={
                              copied
                                ? lang("ucfirst")("G_COPIED")
                                : lang("ucfirst")("G_COPY_TO_CLIPBOARD")
                            }
                            arrow
                          >
                            <Icon
                              fontSize="small"
                              sx={{ mr: 1, cursor: "pointer", color: darkMode ? "#fff" : "black" }}
                            >
                              content_copy
                            </Icon>
                          </Tooltip>
                        </CopyToClipboard>
                        <IconButton
                          onClick={() => setShowApiKey(!showApiKey)}
                          edge="start"
                          sx={{ color: darkMode ? "#fff" : "black" }}
                        >
                          <Icon sx={{ fontSize: "18px !important", margin: "0 -30px 0 -10px" }}>
                            {showApiKey ? "visibility" : "visibility_off"}
                          </Icon>
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: HelpToolTip(lang()("PROVIDERS_FORM_TIP_API_KEY"), darkMode),
                  }}
                  sx={formValues.source ? {} : inputReadOnlySX(darkMode)}
                />
              </MDBox>
              <MDBox mb={2} mt={1} sx={{ opacity: 0.8 }}>
                <MDBox display="flex" alignItems="center">
                  <label
                    htmlFor="default-config-textarea"
                    style={{
                      color: darkMode ? "white" : "black",
                      display: "block",
                      opacity: 0.6,
                      marginRight: "8px", // Space between label and icon
                      fontSize: "0.875rem", // Adjust font size as needed
                      flex: "1", // Ensures label takes available space
                    }}
                  >
                    {lang("ucfirst")("PROVIDERS_FORM_FIELD_DEFAULT_CONFIG_LABEL")}
                  </label>
                  {HelpToolTip(
                    lang()("PROVIDERS_FORM_TIP_FIELD_DEFAULT_CONFIG"),
                    darkMode,
                    "textarea"
                  )}
                </MDBox>
                {defaultConfigElement("150px !important")}
                <MDBox
                  component="span"
                  sx={{
                    position: "absolute",
                    bottom: "90px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    color: darkMode ? "lightgrey !important" : "white !important",
                    cursor: "pointer",
                    textDecoration: "none",
                    padding: "2px 10px",
                    borderRadius: "16px",
                    transition: "background-color 0.3s ease, color 0.3s ease",
                    backgroundColor: "black !important",
                    fontSize: "12px",
                    "&:hover": {
                      backgroundColor: "#2C2C2C !important",
                    },
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTextareaFullscreen(true);
                  }}
                >
                  <MDTypography
                    variant="body2"
                    fontWeight="regular"
                    color="inherit"
                    sx={{ fontSize: "14px" }}
                  >
                    <Icon sx={{ fontSize: "14px" }}>fullscreen</Icon>{" "}
                    {lang("ucfirst")("G_FULLSCREEN")}
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox mt={2}>
                <MDButton
                  type="submit"
                  color={mainColor}
                  variant="gradient"
                  fullWidth
                  disabled={
                    !areRequiredFieldsFilled(requiredFields, formValues) ||
                    !hasChanges(formValues, initialValues) ||
                    formErrors.length > 0
                  }
                >
                  {lang()("G_SAVE")}
                </MDButton>
              </MDBox>
            </TabPanel>
          </MDBox>
        </CardContent>
      </Panel>
    </Tabs>
  );
};

RecordForm.propTypes = {
  record: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
    api_key: PropTypes.string,
    sid: PropTypes.string,
    default_config: PropTypes.object,
    source: PropTypes.string,
    type: PropTypes.string,
  }),
  onEdit: PropTypes.func.isRequired,
  darkMode: PropTypes.bool.isRequired,
  mainColor: PropTypes.string.isRequired,
  providerType: PropTypes.string,
};

export default RecordForm;
