import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useMaterialUIController } from "context";
import { useChatAssistantService } from "services";
import { CircularProgress } from "@mui/material";
import { MDBox } from "components";
import { RecordsGrid } from "./components";
import theme from "assets/theme";

const RecordsList = ({ onEdit, records, setRecords }) => {
  const { getChatAssistants } = useChatAssistantService();
  const [loading, setLoading] = useState(true);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const fetchRecords = useCallback(async () => {
    try {
      const recordsArray = await getChatAssistants();
      setRecords(recordsArray.data);
      console.log(recordsArray.data);
    } catch (error) {
      console.error("Error fetching records:", error);
    } finally {
      setLoading(false);
    }
  }, [setRecords]);

  useEffect(() => {
    fetchRecords();
  }, [fetchRecords]);

  const handleViewDetails = (sid) => {
    onEdit(sid);
  };

  return (
    <>
      {loading ? (
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress
            sx={{
              color: darkMode ? theme.palette.white.main : theme.palette.dark.main,
            }}
          />
        </MDBox>
      ) : (
        <RecordsGrid records={records} onViewDetails={handleViewDetails} />
      )}
    </>
  );
};

RecordsList.propTypes = {
  onEdit: PropTypes.func.isRequired,
  records: PropTypes.array.isRequired,
  setRecords: PropTypes.func.isRequired,
};

export default RecordsList;
