import { useGetServices, backendUrl } from "services"; // Adjust path as needed
import axios from "../axiosConfig";

export const useKnowledgeBaseServices = () => {
  const { getToken, getCurrentWorkspace } = useGetServices();

  const getKnowledgeBaseFiles = async ({ workspace, token } = {}) => {
    console.log("Fetching knowledge base files...");
    const authToken = token || getToken();
    const workspaceParam = workspace || getCurrentWorkspace();
    const response = await axios.get(`${backendUrl}/workspaces/${workspaceParam}/knowledge-base`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  };

  const uploadKnowledgeBaseFile = async ({ file, workspace, token } = {}) => {
    console.log("Uploading file to knowledge base...");
    const authToken = token || getToken();
    const workspaceParam = workspace || getCurrentWorkspace();

    // Create a FormData object to handle file upload
    const formData = new FormData();
    formData.append("filename", file);

    const response = await axios.post(
      `${backendUrl}/workspaces/${workspaceParam}/knowledge-base`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  };

  // Function to delete a file
  const deleteKnowledgeBaseFile = async ({ filename, workspace, token } = {}) => {
    console.log("Deleting file from knowledge base...");
    const authToken = token || getToken();
    const workspaceParam = workspace || getCurrentWorkspace();

    const response = await axios.delete(
      `${backendUrl}/workspaces/${workspaceParam}/knowledge-base`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        params: { filename }, // Send filename as URL query parameter
      }
    );
    return response.data;
  };

  return { getKnowledgeBaseFiles, uploadKnowledgeBaseFile, deleteKnowledgeBaseFile };
};
