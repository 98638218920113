import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { useMaterialUIController } from "context";
import { CircularProgress } from "@mui/material";
import { MDBox } from "components";
import { RecordsGrid } from "./components";
import theme from "assets/theme";
import { useUserContext } from "context/UserContext";

const RecordsList = ({ onEdit, records, setRecords }) => {
  const [loading, setLoading] = useState(true);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const handleViewDetails = (sid) => {
    onEdit(sid);
  };

  const { providers, getProviders, setProviders } = useUserContext();
  const hasFetched = useRef(false);

  const fetchRecords = useCallback(async () => {
    if (!hasFetched.current) {
      try {
        const recordsArray = await getProviders();
        console.log(recordsArray);
        setRecords(recordsArray);
        setProviders(recordsArray);
        hasFetched.current = true;
      } catch (error) {
        console.error("Error fetching records:", error);
      } finally {
        setLoading(false);
      }
    }
  }, [setRecords, getProviders, setProviders]);

  useEffect(() => {
    fetchRecords();
  }, [fetchRecords]);

  return (
    <>
      {loading ? (
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress
            sx={{
              color: darkMode ? theme.palette.white.main : theme.palette.dark.main,
            }}
          />
        </MDBox>
      ) : (
        <RecordsGrid records={records} onViewDetails={handleViewDetails} />
      )}
    </>
  );
};

RecordsList.propTypes = {
  onEdit: PropTypes.func.isRequired,
  records: PropTypes.array.isRequired,
  setRecords: PropTypes.func.isRequired,
};

export default RecordsList;
