import { useUserContext } from "context";
export { useApiKeyServices } from "./apiKeyService";
//export { useGetProviders } from "./providerService";
export { useChatAssistantService } from "./chatAssistantService";
export { useHelpAssistantServices } from "./helpAssiatantService";
export { useKnowledgeBaseServices } from "./knowledgeBaseService";
export { useTestProviderServices } from "./testProviderService";

export const getBackendUrl = () => {
  let url = process.env.REACT_APP_BACKEND_ADDRESS || "/backend";

  if (!url.startsWith("http")) {
    const proxyPanelAddress = process.env.REACT_APP_PROXY_PANEL_ADDRESS;
    const host = window.location.hostname;
    const fullHost = `${window.location.protocol}//${host}`;

    if (proxyPanelAddress && proxyPanelAddress == fullHost) {
      const port = process.env.REACT_APP_BACKEND_PORT || window.location.port;
      url = `${window.location.protocol}//${host}:${port}${url}`;
    } /*else {
      url = `${window.location.protocol}//${host}:${port}${url}`;
    }*/
  }
  console.log("services.getBackendUrl.url", url);
  return url;
};

// @Deprecated alias for getBackendUrl
export const backendUrl = (() => {
  console.warn(
    "Warning: 'backendUrl' is deprecated and will be removed in a future release. Please use 'getBackendUrl()' instead."
  );
  return getBackendUrl();
})();

export const useGetServices = () => {
  const { userToken, currentWorkspace } = useUserContext();

  const getToken = () => {
    if (!userToken) {
      console.error("User token is required but not provided.");
      //throw new Error("User token is required but not provided.");
    }
    return userToken;
  };

  const getCurrentWorkspace = () => {
    if (!currentWorkspace) {
      console.error("User workspace is required but not provided.");
      //throw new Error("User token is required but not provided.");
    }
    return currentWorkspace;
  };

  return { getToken, getCurrentWorkspace };
};
