import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { embedOptions } from "configDefaults";
import {
  CardContent,
  Grid,
  Icon,
  IconButton,
  Select,
  MenuItem,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import { MDTypography, MDBox, Panel } from "components";
import { useMaterialUIController } from "context";
import { lang } from "hooks";

const Embed = ({ embed_sid }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("iframe");
  const [embedValue, setEmbedValue] = useState();
  const iframe_theme = darkMode ? "dark" : "light";
  const start_message = lang("ucfirst")("CONVERSTION_TEST_START_MESSAGE");
  const placeholder_text = lang("ucfirst")("CONVERSTION_TEST_INPUT_PLACEHOLDER_TEXT");
  const error_message = lang("ucfirst")("CONVERSTION_TEST_ERROR_MESSAGE");

  const protocol = window.location.protocol; // e.g., "http:" or "https:"
  //const hostname = window.location.hostname; // e.g., "localhost" or "example.com"
  //const port = window.location.port;

  //const base_url = port ? `${protocol}//${hostname}:${port}` : `${protocol}//${hostname}`;
  const base_url = `${protocol}//embed.${process.env.REACT_APP_MAIN_DOMAIN}`;

  useEffect(() => {
    const iframeSrc = replacePlaceholders(embedOptions.iframe);
    setEmbedValue(iframeSrc);
  }, [embed_sid, darkMode]);

  const replacePlaceholders = (template) => {
    return template
      .replace("{{ base_url }}", base_url)
      .replace("{{ embed_sid }}", embed_sid)
      .replace("{{ theme }}", iframe_theme)
      .replace("{{ placeholder_text }}", placeholder_text)
      .replace("{{ start_message }}", start_message)
      .replace("{{ error_message }}", error_message);
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(embedValue)
      .then(() => {
        setTooltipOpen(true); // Show tooltip on copy
        setTimeout(() => setTooltipOpen(false), 2000); // Hide after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);

    if (value === "iframe") {
      setEmbedValue(replacePlaceholders(embedOptions.iframe));
    } else if (value === "link") {
      setEmbedValue(replacePlaceholders(embedOptions.link));
    } else if (value === "bubble") {
      setEmbedValue(embedOptions.bubble);
    }
  };
  const [selectOpen, setSelectOpen] = useState(false); // State to control dropdown

  const handleIconClick = (event) => {
    event.stopPropagation(); // Prevent label click event from triggering twice
    setSelectOpen((prev) => !prev); // Toggle the open state
  };

  return (
    <Grid item xs={12} lg={12}>
      <Panel darkMode={darkMode}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <MDBox>
            <MDTypography
              variant="h5"
              gutterBottom
              color="error"
              opacity={darkMode ? 0.8 : 1.0}
              fontWeight="regular"
            >
              <Icon fontSize="medium" sx={{ transform: "translateY(4px)" }}>
                code
              </Icon>{" "}
              {lang("ucwords")("EMBED_TITLE")}
            </MDTypography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MDBox>
                  <MDTypography
                    variant="body2"
                    fontWeight="light"
                    sx={{ margin: "2px 0 5px 0" }}
                    opacity={darkMode ? 0.5 : 0.8}
                  >
                    {lang("ucfirst")("EMBED_SELECT_OPTION_LABEL_TEXT")}:
                  </MDTypography>
                  <Select
                    name="embed_type"
                    value={selectedValue}
                    onChange={handleSelectChange}
                    open={selectOpen}
                    onClose={() => setSelectOpen(false)}
                    onOpen={() => setSelectOpen(true)}
                    variant="outlined"
                    margin="none"
                    sx={{ height: 40, minWidth: "200px" }}
                    IconComponent={() => (
                      <IconButton
                        onClick={handleIconClick}
                        sx={{
                          fontSize: "18px",
                          padding: "0 5px",
                          color: darkMode ? "lightgrey" : "black",
                        }}
                      >
                        <Icon>expand_more</Icon>
                      </IconButton>
                    )}
                  >
                    <MenuItem value="iframe">{lang("ucwords")("G_IFRAME")}</MenuItem>
                    <MenuItem value="link">{lang("ucwords")("G_DIRECT_LINK")}</MenuItem>
                  </Select>
                </MDBox>

                <MDBox mt={2}>
                  <MDTypography
                    variant="body2"
                    gutterBottom
                    //color="error"
                    opacity={0.6}
                    fontWeight="regular"
                  >
                    {lang("ucwords")("options")}:
                  </MDTypography>
                  <MDBox>
                    <MDTypography variant="body2" gutterBottom opacity={darkMode ? 0.5 : 0.8}>
                      <strong>- theme</strong> (Optional): A theme for the colors, values are
                      light|dark. <em>Default: &ldquo;light&ldquo;</em>
                    </MDTypography>
                    <MDTypography variant="body2" gutterBottom opacity={darkMode ? 0.5 : 0.8}>
                      <strong>- start_message</strong> (Optional): Adds a start message to the
                      assistant. <em>Default: None</em>
                    </MDTypography>
                    <MDTypography variant="body2" gutterBottom opacity={darkMode ? 0.5 : 0.8}>
                      <strong>- placeholder_text</strong> (Optional): Text for the placeholder
                      input.{" "}
                      <em>
                        Default: &ldquo;{lang("ucfirst")("CONVERSTION_TEST_INPUT_PLACEHOLDER_TEXT")}
                        &ldquo;
                      </em>
                    </MDTypography>
                    <MDTypography variant="body2" gutterBottom opacity={darkMode ? 0.5 : 0.8}>
                      <strong>- error_message</strong> (Optional): Text to be displayed whenever an
                      error occurs with the assistant response input.{" "}
                      <em>
                        Default: &ldquo;{lang("ucfirst")("CONVERSTION_TEST_ERROR_MESSAGE")}&ldquo;
                      </em>
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox
                  mt={2}
                  sx={{
                    position: "relative",
                    width: {
                      xs: "100%", // Full width on extra small to medium screens
                      sm: "100%",
                      md: "100%",
                      lg: "100%", // 800px on large screens
                      xl: "100%", // 800px on extra large screens
                    },
                  }}
                >
                  <TextareaAutosize
                    name="embed_value"
                    minRows={10}
                    value={embedValue}
                    style={{
                      borderColor: darkMode ? "rgba(255, 255, 255, 0.5)" : "rgb(12 10 10 / 12%)",
                      outline: "none",
                      width: "100%",
                      //height: "100px !important",
                      padding: "12px",
                      fontSize: "0.9rem",
                      resize: "vertical",
                      overflow: "auto",
                      //minHeight: "80px",
                      color: darkMode ? "white" : "black",
                      backgroundColor: "transparent",
                      borderRadius: "8px",
                    }}
                    readOnly
                  />

                  <Tooltip
                    title={lang("ucwords")("G_COPIED")}
                    open={tooltipOpen}
                    arrow
                    placement="top"
                  >
                    <MDBox
                      component="span"
                      sx={{
                        position: "absolute",
                        bottom: 15,
                        left: "50%",
                        transform: "translateX(-50%)",
                        color: darkMode ? "lightgrey !important" : "white !important",
                        cursor: "pointer",
                        textDecoration: "none",
                        padding: "2px 10px",
                        borderRadius: "16px",
                        transition: "background-color 0.3s ease, color 0.3s ease",
                        backgroundColor: "black !important",
                        fontSize: "12px",
                        "&:hover": {
                          backgroundColor: "#2C2C2C !important",
                        },
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        copyToClipboard();
                      }}
                    >
                      {lang("ucwords")("G_COPY_TO_CLIPBOARD")}
                    </MDBox>
                  </Tooltip>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </CardContent>
      </Panel>
    </Grid>
  );
};

Embed.propTypes = {
  embed_sid: PropTypes.string,
};

export default Embed;
