import { useGetServices, backendUrl } from "services"; // Adjust path as needed
import axios from "../axiosConfig";

export const useChatAssistantService = () => {
  const { getToken, getCurrentWorkspace } = useGetServices();

  const getChatAssistants = async ({ workspace, token } = {}) => {
    console.log("Fetching chat assistants...");
    const authToken = token || getToken();
    const workspaceParam = workspace || getCurrentWorkspace();
    const response = await axios.get(`${backendUrl}/workspaces/${workspaceParam}/assistants/chat`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  };

  const getChatPromptTemplates = async ({ token } = {}) => {
    console.log("Fetching chat assistants prompt templates...");
    const authToken = token || getToken();
    const response = await axios.get(`${backendUrl}/assistants/chat/templates`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  };

  const addChatAssistant = async ({ workspace, token, name, model } = {}) => {
    console.log("Adding chat assistant...");
    const authToken = token || getToken();
    const workspaceParam = workspace || getCurrentWorkspace();
    const response = await axios.post(
      `${backendUrl}/workspaces/${workspaceParam}/assistants/chat`,
      {
        name,
        model,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  };

  const editChatAssistant = async ({ workspace, token, sid, name, model } = {}) => {
    console.log("Editing chat assistant...", sid);
    const authToken = token || getToken();
    const workspaceParam = workspace || getCurrentWorkspace();
    const response = await axios.patch(
      `${backendUrl}/workspaces/${workspaceParam}/assistants/chat/${sid}`,
      {
        name,
        model,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  };

  const deleteChatAssistant = async ({ workspace, token, sid } = {}) => {
    console.log("Deleting api key...", sid);
    const authToken = token || getToken();
    const workspaceParam = workspace || getCurrentWorkspace();
    const response = await axios.delete(
      `${backendUrl}/workspaces/${workspaceParam}/assistants/chat/${sid}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  };

  return {
    getChatAssistants,
    addChatAssistant,
    editChatAssistant,
    deleteChatAssistant,
    getChatPromptTemplates,
  };
};
