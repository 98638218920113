import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { useMaterialUIController } from "context";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import DOMPurify from "dompurify";

const DialogComponent = ({
  open,
  onClose,
  title,
  message,
  onButtonClick,
  buttonText,
  closeOnBackdropClick = true, // Default to true
}) => {
  const [internalOpen, setInternalOpen] = useState(open);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const myTheme = darkMode ? themeDark : theme;

  // Sanitize the HTML message to avoid XSS attacks
  const sanitizedMessage = DOMPurify.sanitize(message || "Default message.");

  useEffect(() => {
    setInternalOpen(open); // Sync internal state with the open prop
  }, [open]);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick" && !closeOnBackdropClick) {
      // Do nothing if clicking on the backdrop and closeOnBackdropClick is false
      return;
    }
    setInternalOpen(false); // Close the dialog by setting the internal state
    if (onClose && typeof onClose === "function") {
      onClose(event, reason); // Call the onClose prop if it exists
    }
  };

  return (
    <Dialog
      open={internalOpen}
      onClose={handleClose} // Use the custom handleClose function
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          backgroundColor: darkMode ? myTheme.palette.dark.main : myTheme.palette.white.main,
          color: darkMode ? myTheme.palette.dark.main : myTheme.palette.white.main,
        },
      }}
      disableEscapeKeyDown={!closeOnBackdropClick} // Prevent closing with the Escape key if closeOnBackdropClick is false
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          color: darkMode ? myTheme.palette.white.main : myTheme.palette.dark.main,
        }}
      >
        {title || "Dialog Title"}
      </DialogTitle>
      <DialogContent
        sx={{
          color: darkMode ? myTheme.palette.white.main : myTheme.palette.dark.main,
        }}
      >
        <DialogContentText
          sx={{
            color: darkMode ? myTheme.palette.white.main : myTheme.palette.dark.main,
            padding: "20px 0",
          }}
          id="alert-dialog-description"
          dangerouslySetInnerHTML={{ __html: sanitizedMessage }}
        />
      </DialogContent>
      <DialogActions
        sx={{
          color: darkMode ? myTheme.palette.white.main : myTheme.palette.dark.main,
        }}
      >
        <Button
          onClick={() => {
            if (onButtonClick) {
              onButtonClick();
            }
            setInternalOpen(false); // Ensure dialog is closed on button click
          }}
          color="primary"
          autoFocus
          sx={{
            color: darkMode ? myTheme.palette.white.main : myTheme.palette.dark.main,
          }}
        >
          {buttonText || "Close"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Define PropTypes for the component
DialogComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  onButtonClick: PropTypes.func,
  buttonText: PropTypes.string,
  closeOnBackdropClick: PropTypes.bool, // Ensure this is included
};

export default DialogComponent;
