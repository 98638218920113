import React, { useState } from "react";
import { useMaterialUIController } from "context";
import { useSnackbar, lang } from "hooks";
import { useGetProviders } from "services/providerService";
import { Icon } from "@mui/material";
import { DashboardLayout, DashboardNavbar, MDButton, AddProviderDialog } from "components";
import ListRecords from "./vi.list";
import RecordDetails from "./vi.details";

const Providers = () => {
  const [controller] = useMaterialUIController();
  const { sidenavColor, darkMode } = controller;
  const [currentView, setCurrentView] = useState("list");
  const [currentRecord, setCurrentRecord] = useState(null);
  const [records, setRecords] = useState([]);
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  const { deleteProvider } = useGetProviders();
  const [openProviderDialog, setOpenProviderDialog] = useState(false);
  const [providerType, setProviderType] = useState(null);

  const handleProviderDialogOpen = () => {
    setOpenProviderDialog(true);
  };

  const handleProviderDialogClose = () => {
    setOpenProviderDialog(false);
  };

  const gotoProviderForm = (selectedOption) => {
    setProviderType(selectedOption);
    handleProviderDialogClose();
    setCurrentView("details");
  };

  const handleGridEditBtn = (sid) => {
    if (sid) {
      const record = records.find((p) => p.sid === sid);
      setCurrentRecord(record);
    } else {
      setCurrentRecord(null);
    }
    setCurrentView("details");
  };

  const handleDelete = {
    action: async ({ sid }) => {
      await deleteProvider({ sid });
    },
    callback: () => {
      setCurrentView("list");
      showSnackbar("success", lang("ucfirst")("G_FORM_DELETE_SUCCESS"));
    },
  };

  const handleEdit = (isNew = false) => {
    setCurrentView("list");
    if (isNew) {
      showSnackbar("success", lang("ucfirst")("G_FORM_ADD_SUCCESS"));
    } else {
      showSnackbar("success", lang("ucfirst")("G_FORM_EDIT_SUCCESS"));
    }
  };

  const goBack = () => {
    setCurrentView("list");
    setCurrentRecord(null); // Clear selected provider ID when going back
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <AddProviderDialog
        open={openProviderDialog}
        onClose={handleProviderDialogClose}
        onSubmit={gotoProviderForm}
        btnColor={sidenavColor}
        darkMode={darkMode}
      />
      {SnackbarComponent}
      {currentView === "list" ? (
        <div>
          <MDButton
            color={sidenavColor}
            variant="gradient"
            onClick={handleProviderDialogOpen}
            style={{ display: "flex", alignItems: "center", margin: "15px 0px" }}
          >
            {lang()("PROVIDERS_ADD_BTN")}
          </MDButton>
          <ListRecords onEdit={handleGridEditBtn} records={records} setRecords={setRecords} />
        </div>
      ) : (
        <div>
          <MDButton
            color="error"
            variant="text"
            onClick={goBack}
            style={{
              display: "flex",
              alignItems: "center",
              margin: "15px 0px",
              paddingLeft: "10px",
            }}
          >
            <Icon style={{ marginRight: "4px" }}>arrow_back</Icon>
            {lang()("G_GO_BACK")}
          </MDButton>
          <RecordDetails
            record={currentRecord}
            onDelete={handleDelete}
            onEdit={handleEdit}
            providerType={providerType}
          />
        </div>
      )}
    </DashboardLayout>
  );
};

export default Providers;
