import React, { useState } from "react";
import PropTypes from "prop-types";
import { IconButton, Icon, Tooltip } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { lang } from "hooks";
import theme from "assets/theme";

export const ActionsCell = ({ row, darkMode, onViewDetails }) => {
  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <IconButton
        onClick={() => onViewDetails(row.original.sid)}
        style={{ marginLeft: "8px", padding: "0px 4px 0 0" }}
      >
        <Icon
          sx={{
            color: darkMode ? theme.palette.white.main : theme.palette.dark.main,
            fontSize: "18px !important",
          }}
        >
          edit
        </Icon>
      </IconButton>
    </div>
  );
};

ActionsCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      sid: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  darkMode: PropTypes.bool.isRequired,
  onViewDetails: PropTypes.func.isRequired,
};

export const ActionsCellV2 = ({ sid, darkMode, editAction, deleteAction }) => {
  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      {editAction && (
        <IconButton
          onClick={() => editAction(sid)}
          style={{ marginLeft: "8px", padding: "0px 4px 0 0" }}
        >
          <Icon
            sx={{
              color: darkMode ? theme.palette.white.main : theme.palette.dark.main,
              fontSize: "18px !important",
            }}
          >
            edit
          </Icon>
        </IconButton>
      )}
      {deleteAction && (
        <IconButton
          onClick={() => deleteAction(sid)}
          style={{ marginLeft: "8px", padding: "0px 4px 0 0" }}
        >
          <Icon
            sx={{
              color: darkMode ? theme.palette.error.main : theme.palette.dark.main,
              fontSize: "18px !important",
            }}
          >
            delete
          </Icon>
        </IconButton>
      )}
    </div>
  );
};

ActionsCellV2.propTypes = {
  sid: PropTypes.string.isRequired,
  darkMode: PropTypes.bool.isRequired,
  editAction: PropTypes.func,
  deleteAction: PropTypes.func,
};

export const ApiKeyCell = ({
  value,
  row,
  toggleApiKeyVisibility,
  showApiKeys,
  darkMode,
  minWidth = "340px",
  maxWidth = "340px",
}) => {
  const [copied, setCopied] = useState(false);
  const maskedValue = value
    ? "*".repeat(Math.min(value.length, 32)) + (value.length > 32 ? "..." : "")
    : "";

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div style={{ display: "flex", alignItems: "center", minWidth: minWidth, maxWidth: maxWidth }}>
      <CopyToClipboard text={value} onCopy={handleCopy}>
        <Tooltip
          title={copied ? lang("ucfirst")("G_COPIED") : lang("ucfirst")("G_COPY_TO_CLIPBOARD")}
          arrow
        >
          <IconButton style={{ marginLeft: "0px", padding: "0px 4px 0 0" }}>
            <Icon
              sx={{
                color: darkMode ? theme.palette.white.main : theme.palette.dark.main,
                fontSize: "18px !important",
              }}
            >
              content_copy_outlined
            </Icon>
          </IconButton>
        </Tooltip>
      </CopyToClipboard>
      <IconButton
        onClick={() => toggleApiKeyVisibility(row.original.sid)}
        style={{ padding: "0px 4px 0 0" }}
      >
        <Icon
          sx={{
            color: darkMode ? theme.palette.white.main : theme.palette.dark.main,
            fontSize: "18px !important",
          }}
        >
          {showApiKeys[row.original.sid] ? "visibility_off" : "visibility"}
        </Icon>
      </IconButton>
      <span
        style={{
          marginLeft: "4px",
          lineHeight: "18px",
          wordBreak: "break-word",
          overflowWrap: "break-word",
          whiteSpace: "pre-wrap",
          maxWidth: "calc(100% - 50px)",
        }}
      >
        {showApiKeys[row.original.sid] ? value : maskedValue}
      </span>
    </div>
  );
};

ApiKeyCell.propTypes = {
  value: PropTypes.string.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      sid: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  toggleApiKeyVisibility: PropTypes.func.isRequired,
  showApiKeys: PropTypes.object.isRequired,
  darkMode: PropTypes.bool.isRequired,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
};

// SidCell Component
export const SidCell = ({ row, darkMode }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <CopyToClipboard text={row.original.sid} onCopy={handleCopy}>
        <Tooltip
          title={copied ? lang("ucfirst")("G_COPIED") : lang("ucfirst")("G_COPY_TO_CLIPBOARD")}
          arrow
        >
          <IconButton style={{ marginLeft: "0px", padding: "0px 4px 0 0" }}>
            <Icon
              sx={{
                color: darkMode ? theme.palette.white.main : theme.palette.dark.main,
                fontSize: "18px !important",
              }}
            >
              content_copy_outlined
            </Icon>
          </IconButton>
        </Tooltip>
      </CopyToClipboard>
      {row.original.sid}
    </div>
  );
};

SidCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      sid: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  darkMode: PropTypes.bool.isRequired,
};
