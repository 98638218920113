import { useGetServices, backendUrl } from "services"; // Adjust path as needed
import axios from "../axiosConfig";

export const useTestProviderServices = () => {
  const { getToken } = useGetServices();

  const testConnection = async ({
    token,
    type,
    source,
    api_key,
    address,
    model,
    args = {},
  } = {}) => {
    const authToken = token || getToken();
    const response = await axios.post(
      `${backendUrl}/test-connection`,
      {
        type,
        source,
        api_key,
        address,
        model,
        args,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  };

  return { testConnection };
};
