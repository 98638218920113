import { useGetServices, backendUrl } from "services"; // Adjust path as needed
import axios from "../axiosConfig";

export const useApiKeyServices = () => {
  const { getToken, getCurrentWorkspace } = useGetServices();

  const getApiKeys = async ({ workspace, token } = {}) => {
    console.log("Fetching api keys...");
    const authToken = token || getToken();
    const workspaceParam = workspace || getCurrentWorkspace();
    const response = await axios.get(`${backendUrl}/workspaces/${workspaceParam}/api-keys`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  };

  const addApiKey = async ({ workspace, token, name, type } = {}) => {
    console.log("Fetching api keys...");
    const authToken = token || getToken();
    const workspaceParam = workspace || getCurrentWorkspace();
    const response = await axios.post(
      `${backendUrl}/workspaces/${workspaceParam}/api-keys`,
      {
        name,
        type,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  };

  const editApiKey = async ({ workspace, token, sid, name } = {}) => {
    console.log("Editing api key...", sid);
    const authToken = token || getToken();
    const workspaceParam = workspace || getCurrentWorkspace();
    const response = await axios.patch(
      `${backendUrl}/workspaces/${workspaceParam}/api-keys/${sid}`,
      {
        name,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  };

  const deleteApiKey = async ({ workspace, token, sid } = {}) => {
    console.log("Deleting api key...", sid);
    const authToken = token || getToken();
    const workspaceParam = workspace || getCurrentWorkspace();
    const response = await axios.delete(
      `${backendUrl}/workspaces/${workspaceParam}/api-keys/${sid}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  };

  return { getApiKeys, addApiKey, editApiKey, deleteApiKey };
};
