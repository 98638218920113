import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSnackbar, lang, useSelectToggle } from "hooks";
import { useApiKeyServices } from "services";
import {
  Icon,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CardContent,
  IconButton,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import { MDBox, MDTypography, MDInput, MDButton, Panel } from "components";
import {
  inputReadOnlySX,
  areRequiredFieldsFilled,
  hasChanges,
  formatErrorMsg,
  HelpToolTip,
  CustomBadge,
} from "utils";
import { CopyToClipboard } from "react-copy-to-clipboard";

const RecordForm = ({ record, onEdit, darkMode, mainColor }) => {
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  const { addApiKey, editApiKey } = useApiKeyServices();
  const [formErrors, setFormErrors] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [readOnlyFields, setReadOnlyFields] = useState([]);
  const { selectOpen, handleIconClick, closeSelect, openSelect } = useSelectToggle();
  const [formValues, setFormValues] = useState({
    name: "",
    type: "",
    api_key: "",
  });
  const [requiredFields] = useState(["name", "type"]);

  useEffect(() => {
    if (record) {
      const { name, type, api_key } = record;
      setFormValues({ name, type, api_key });
      setInitialValues({ name, type, api_key });
      setReadOnlyFields(["type", "api_key"]);
    }
  }, [record]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setFormErrors((prevErrors) => prevErrors.filter((errorField) => errorField !== name));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, type } = formValues;
    let errorMsg = "";
    try {
      if (record) {
        const response = await editApiKey({ name, type, sid: record.sid });
        if (response.status_code === 200) {
          onEdit(false);
        } else if ((errorMsg = formatErrorMsg(response.data))) {
          setFormErrors(Object.keys(response.data.details));
          showSnackbar("error", errorMsg);
        } else {
          showSnackbar("error", lang("ucfirst")("G_FORM_ERROR"));
        }
      } else {
        const response = await addApiKey({ name, type });
        if (response.status_code === 200) {
          onEdit(true);
        } else if ((errorMsg = formatErrorMsg(response.data))) {
          setFormErrors(Object.keys(response.data.details));
          showSnackbar("error", errorMsg);
        } else {
          showSnackbar("error", lang("ucfirst")("G_FORM_ERROR"));
        }
      }
    } catch (error) {
      console.error("Error handling record:", error);
      showSnackbar("error", lang("ucfirst")("G_FORM_ERROR"));
    }
  };

  const [showApiKey, setShowApiKey] = useState(false);
  const [copied, setCopied] = useState(false);
  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };

  return (
    <Panel darkMode={darkMode}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <MDBox component="form" mt={1} onSubmit={handleSubmit}>
          {SnackbarComponent}
          <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <MDTypography variant="h5" fontWeight="regular" sx={{ opacity: 0.6 }}>
              <Icon fontSize="medium" sx={{ transform: "translateY(4px)" }}>
                {record ? "edit" : "add_circle_outline"}
              </Icon>{" "}
              {record
                ? lang("ucwords")("API_KEY_EDIT_RECORD_TITLE")
                : lang("ucwords")("API_KEY_NEW_RECORD_TITLE")}
            </MDTypography>
            {record && <CustomBadge text={record.sid} lang={lang} darkMode={darkMode} />}
          </MDBox>
          <MDBox mb={0} mt={2} sx={{ opacity: 0.8 }}>
            <MDInput
              fullWidth
              name="name"
              label={`${lang("ucwords")("G_NAME")}*`}
              value={formValues.name}
              onChange={handleInputChange}
              error={formErrors.includes("name")}
              InputProps={{
                endAdornment:
                  !formErrors.includes("name") &&
                  HelpToolTip(lang()("G_FORM_TIP_FIELD_NAME"), darkMode),
              }}
            />
          </MDBox>
          {record ? (
            <MDBox>
              <MDBox mb={1} mt={2} sx={{ opacity: darkMode ? 0.4 : 0.6 }}>
                <MDInput
                  fullWidth
                  name="type"
                  label={`${lang("ucwords")("G_TYPE")}*`}
                  value={formValues.type}
                  onChange={handleInputChange}
                  InputProps={{
                    readOnly: readOnlyFields.includes("type"),
                    endAdornment: HelpToolTip(lang()("API_KEY_FORM_TIP_TYPE"), darkMode),
                  }}
                  sx={inputReadOnlySX(darkMode)}
                />
              </MDBox>
              <MDBox mb={1} mt={2} sx={{ opacity: darkMode ? 0.4 : 0.6 }}>
                <MDInput
                  fullWidth
                  name="api_key"
                  type={showApiKey ? "text" : "password"}
                  label={`${lang("ucwords")("G_API_KEY")}*`}
                  value={formValues.api_key}
                  onChange={handleInputChange}
                  InputProps={{
                    readOnly: readOnlyFields.includes("api_key"),
                    startAdornment: (
                      <InputAdornment position="start">
                        <CopyToClipboard text={formValues.api_key} onCopy={handleCopy}>
                          <Tooltip
                            title={
                              copied
                                ? lang("ucfirst")("G_COPIED")
                                : lang("ucfirst")("G_COPY_TO_CLIPBOARD")
                            }
                            arrow
                          >
                            <Icon
                              fontSize="small"
                              sx={{ mr: 1, cursor: "pointer", color: darkMode ? "#fff" : "black" }}
                            >
                              content_copy
                            </Icon>
                          </Tooltip>
                        </CopyToClipboard>
                        <IconButton
                          onClick={() => setShowApiKey(!showApiKey)}
                          edge="start"
                          sx={{ color: darkMode ? "#fff" : "black" }}
                        >
                          <Icon sx={{ fontSize: "18px !important", margin: "0 -30px 0 -10px" }}>
                            {showApiKey ? "visibility" : "visibility_off"}
                          </Icon>
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: HelpToolTip(lang()("API_KEY_FORM_TIP_API_KEY"), darkMode),
                  }}
                  sx={inputReadOnlySX(darkMode)}
                />
              </MDBox>
            </MDBox>
          ) : (
            <MDBox mb={1} sx={{ opacity: 0.8 }}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="model-type-label">Type*</InputLabel>
                <Select
                  name="type"
                  labelId="model-type-label"
                  value={formValues.type}
                  onChange={handleInputChange}
                  open={selectOpen["type"] || false}
                  onClose={() => closeSelect("type")}
                  onOpen={() => openSelect("type")}
                  variant="outlined"
                  margin="none"
                  sx={{ minHeight: 40 }}
                  IconComponent={() =>
                    HelpToolTip(lang()("API_KEY_FORM_TIP_TYPE"), darkMode, "select", (event) =>
                      handleIconClick("type", event)
                    )
                  }
                >
                  {/*<MenuItem value="public">Public</MenuItem>*/}
                  <MenuItem value="private">Private</MenuItem>
                </Select>
              </FormControl>
            </MDBox>
          )}
          <MDBox mt={2}>
            <MDButton
              type="submit"
              color={mainColor}
              variant="gradient"
              fullWidth
              disabled={
                !areRequiredFieldsFilled(requiredFields, formValues) ||
                !hasChanges(formValues, initialValues) ||
                formErrors.length > 0
              }
            >
              {lang()("G_SAVE")}
            </MDButton>
          </MDBox>
        </MDBox>
      </CardContent>
    </Panel>
  );
};

RecordForm.propTypes = {
  record: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    sid: PropTypes.string,
    api_key: PropTypes.string,
  }),
  onEdit: PropTypes.func.isRequired,
  darkMode: PropTypes.bool.isRequired,
  mainColor: PropTypes.string.isRequired,
};

export default RecordForm;
