// configDefaults.js

export const backendAddress = "/api";

export const validFileTypes = [
  "text/plain",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const providersConfigDefaults = {
  model: {
    default: { model: "please add your model here" },
    openai: { model: "gpt-4o", temperature: 0.7 },
    anthropic: { model: "claude-3.5", temperature: 0.7 },
    ollama: { model: "llama3.1", temperature: 0.7 },
    cohere: { model: "embed-english-v3.0", temperature: 0.7 },
  },
  transcriber: {
    default: { model: "please add your transcriber here" },
    openai: { model: "whisper-1" },
  },
  addresses: {
    default: "",
    openai: "https://api.openai.com/v1",
    anthropic: "https://api.anthropic.com",
    cohere: "https://api.cohere.ai/v1",
  },
};

export const testConversationEnginesDefaults = {
  openAISTTConfig: `{ "model": "whisper-1" }`,
  fwSTTConfig: `{ "model": "small", "language": "en" }`,
  openAILLMConfig: `{ "model": "gpt-4o" }`,
  ollamaLLMConfig: `{ "model": "dolphin-llama3" }`,
  lmStudioLLMConfig: `{ "model": "local-model" }`,
  openAIFCConfig:
    '[{"type": "function", "function": {"name": "end_conversation", "description": "Execute this function when user says goodbye."}}, {"type": "function", "function": {"name": "get_current_weather", "description": "Get the current weather", "parameters": {"type": "object", "properties": {"location": {"type": "string", "description": "The city and state, e.g. San Francisco"}, "unit": {"type": "string", "enum": ["celsius", "fahrenheit"], "description": "The temperature unit to use. Infer this from the users location."}}, "required": ["location", "unit"]}}}]',
  ollamaFCConfig: "",
  lmStudioFCConfig: "",
  openAITTSConfig: `{ "voice": "alloy", "speed":"1.0", "model": "tts-1" }`,
  openVoiceV1Config: `{ "model": "en", "voice": "mark", "style": "default", "speed": "1.0" }`,
  openVoiceV2Config: `{ "model": "en_newest","voice": "mark", "accent": "en-newest", "speed": "1.0" }`,
  elevenLabsTTSConfig: `{ "model": "eleven_monolingual_v1", "voice": "Rachel" , "voice_settings": { "stability": 0.0, "similarity_boost": 1.0, "style": 0.0, "use_speaker_boost": false } }`,
  defaultVCConfig: "",
  openVoiceV1VCConfig: `{ "voice": "mark" }`,
  openVoiceV2VCConfig: `{ "voice": "mark" }`,
};

export const testConversationFormDefaults = {
  systemMessage: "Always talk in rhymes, use a maximum of 30 words with each reply.",
  systemMessageBotTwo: "Tell short poems, use a maximum of 30 words for each poem.",
  maxHistory: 10,
  selectSTTSource: "openai",
  selectLLMSource: "openai",
  selectTTSSource: "openai",
  selectVCSource: "none",
  sttConfig: testConversationEnginesDefaults.openAISTTConfig,
  llmConfig: testConversationEnginesDefaults.openAILLMConfig,
  fcConfig: testConversationEnginesDefaults.openAIFCConfig,
  ttsConfig: testConversationEnginesDefaults.openAITTSConfig,
  vcConfig: testConversationEnginesDefaults.defaultVCConfig,
};

export const testConversation = {
  debugLevel: 0,
  enginesDefaults: testConversationEnginesDefaults,
  formDefaults: testConversationFormDefaults,
};

export const embedOptions = {
  iframe:
    '<iframe src="{{ base_url }}/iframe/{{ embed_sid }}?theme={{ theme }}&placeholder_text={{ placeholder_text }}&start_message={{ start_message }}&error_message={{ error_message }}" allow="clipboard-write; clipboard-read; *;microphone *" width="100%" height="100%"></iframe>',
  link: "{{ base_url }}/chat/{{ embed_sid }}?theme={{ theme }}&placeholder_text={{ placeholder_text }}&start_message={{ start_message }}&error_message={{ error_message }}",
  bubble: "",
};
