import { lang } from "hooks";

// Import form utility functions
export {
  areRequiredFieldsFilled,
  hasChanges,
  formatErrorMsg,
  inputReadOnlySX,
  inputDisabledSX,
  TextareaSX,
  HelpToolTip,
  CustomBadge,
  collectKeys,
  inputErrorSX,
} from "./formUtils";

export { ActionsCell, ActionsCellV2, ApiKeyCell, SidCell } from "./gridUtils.js";

export const replacePlaceholdersWithTranslations = (text, transformation = null) => {
  const regex = /{([^}]+)}/g;
  return text.replace(regex, (_, key) => {
    return lang(transformation)(key) || key;
  });
};
