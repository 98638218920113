// components/DangerZone.js

import React, { useState } from "react";
import PropTypes from "prop-types";
import theme from "assets/theme";
import { CardContent, Grid, Icon } from "@mui/material";
import { DialogComponent, Panel, MDBox, MDTypography, MDButton } from "components";
import { useApiKeyServices } from "services";
import { useMaterialUIController } from "context";
import { lang } from "hooks";

const DangerZone = ({ onDelete, record, deleteText = "Delete record" }) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState("");
  const { deleteApiKey } = useApiKeyServices();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const handleDelete = () => {
    setDeleteMsg("<strong style=' color: red; margin: 20px;'>Please confirm action.</strong>");
    setDeleteDialogOpen(true);
  };

  const handleDoDeleteRecord = async () => {
    try {
      await onDelete.action({ sid: record.sid });
      setDeleteDialogOpen(false);
      onDelete.callback();
    } catch (error) {
      console.error("Error deleting record:", error);
    }
  };

  const handleDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  return (
    <Grid item xs={12} lg={12}>
      <DialogComponent
        open={deleteDialogOpen}
        onClose={handleDialogClose}
        title="Delete record"
        message={deleteMsg}
        onButtonClick={handleDoDeleteRecord}
        buttonText="Delete"
        closeOnBackdropClick={true}
      />
      <Panel darkMode={darkMode}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <MDBox>
            <MDTypography
              variant="h5"
              gutterBottom
              color="error"
              opacity={darkMode ? 0.8 : 1.0}
              sx={{ color: darkMode ? "#FFB6C1" : theme.palette.error.main }}
              fontWeight="regular"
            >
              <Icon fontSize="medium" sx={{ transform: "translateY(4px)" }}>
                error_outline
              </Icon>{" "}
              {lang("ucwords")("G_DANGER_ZONE")}
            </MDTypography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Panel
                  sx={{
                    borderColor: darkMode ? "#FFB6C1" : theme.palette.error.main,
                    //borderOpacity: 0.9,
                    height: "auto",
                    paddingLeft: 2,
                    paddingRight: 2,
                    paddingTop: 1.5,
                    paddingBottom: 0,
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      padding: 1,
                    }}
                  >
                    <MDBox sx={{ color: "red" }}>
                      <MDTypography
                        variant="body2"
                        fontWeight="light"
                        sx={{ marginTop: "2px" }}
                        opacity={darkMode ? 0.5 : 0.8}
                      >
                        {deleteText}
                      </MDTypography>
                    </MDBox>
                    <MDButton
                      color="error"
                      variant={darkMode ? "outlined" : "contained"}
                      sx={{ marginLeft: "auto" }}
                      onClick={handleDelete}
                    >
                      {lang("ucfirst")("G_DELETE")}
                    </MDButton>
                  </CardContent>
                </Panel>
              </Grid>
            </Grid>
          </MDBox>
        </CardContent>
      </Panel>
    </Grid>
  );
};

DangerZone.propTypes = {
  onDelete: PropTypes.shape({
    action: PropTypes.func.isRequired,
    callback: PropTypes.func.isRequired,
  }).isRequired,
  record: PropTypes.object.isRequired,
  deleteText: PropTypes.string,
};

export default DangerZone;
