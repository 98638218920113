import { useGetServices, backendUrl } from "services";
import axios from "../axiosConfig";

export const useGetProviders = () => {
  const { getToken, getCurrentWorkspace } = useGetServices();

  const getProviders = async ({ workspace, token } = {}) => {
    console.log("Fetching providers...");
    const authToken = token || getToken();
    const workspaceParam = workspace || getCurrentWorkspace();
    const response = await axios.get(`${backendUrl}/workspaces/${workspaceParam}/providers`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  };

  const addProvider = async ({
    workspace,
    token,
    name,
    type,
    source,
    address,
    api_key,
    default_config,
  } = {}) => {
    console.log("Adding provider...");
    const authToken = token || getToken();
    const workspaceParam = workspace || getCurrentWorkspace();
    const response = await axios.post(
      `${backendUrl}/workspaces/${workspaceParam}/providers`,
      {
        name,
        type,
        source,
        address,
        api_key,
        default_config,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  };

  const editProvider = async ({
    sid,
    workspace,
    token,
    name,
    type,
    source,
    address,
    api_key,
    default_config,
  } = {}) => {
    console.log("Editing provider...");
    const authToken = token || getToken();
    const workspaceParam = workspace || getCurrentWorkspace();
    const response = await axios.patch(
      `${backendUrl}/workspaces/${workspaceParam}/providers/${sid}`,
      {
        name,
        type,
        source,
        address,
        api_key,
        default_config,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  };

  const deleteProvider = async ({ sid, token, workspace } = {}) => {
    console.log("Deleting provider...", sid);
    const authToken = token || getToken();
    const workspaceParam = workspace || getCurrentWorkspace();
    const response = await axios.delete(
      `${backendUrl}/workspaces/${workspaceParam}/providers/${sid}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  };

  return { getProviders, addProvider, editProvider, deleteProvider };
};
