import { useState, useEffect, useMemo } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "styles/Tabs.css";
import "styles/Fields.css";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import Icon from "@mui/material/Icon";
import { MDBox, Sidenav, Configurator } from "./components";
import { replacePlaceholdersWithTranslations } from "./utils";
//import { CacheProvider } from "@emotion/react";
//import createCache from "@emotion/cache";
import routes from "routes";
//import { useUserContext } from "context";
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
  useUserContext,
} from "context";
import brandDark from "assets/images/logo.png";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const { userData, isSuperAdmin } = useUserContext();
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const isSuperAdminUser = useMemo(() => isSuperAdmin(), [userData]);
  const brandWhite = brandDark;

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.flatMap((route) => {
      if (route.children) {
        if (route.key == "legacy" && !isSuperAdminUser) {
          return;
        }
        route.name = replacePlaceholdersWithTranslations(route.name, "ucwords");
        return [
          route.route && !route.disabled ? (
            <Route exact path={route.route} element={route.component} key={route.key} />
          ) : null,
          ...getRoutes(route.children),
        ];
      }
      route.name = replacePlaceholdersWithTranslations(route.name, "ucwords");
      if (route.route && !route.disabled) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return [];
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      style={{
        backgroundColor: darkMode ? "#344767" : "white",
        color: darkMode ? "white" : theme.palette.secondary.main,
      }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="large" color="inherit">
        admin_panel_settings
      </Icon>
    </MDBox>
  );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />

      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName={process.env.REACT_APP_BRAND_NAME}
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          {isSuperAdminUser && (
            <>
              <Configurator />
              {configsButton}
            </>
          )}
          <Routes>
            {getRoutes(routes)}
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Routes>
        </>
      )}
    </ThemeProvider>
  );
}
