import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox"; // Adjust this import based on your project setup

const LoadingOverlay = ({ isLoading, children, size = 60, color = "lightblue" }) => (
  <MDBox sx={{ position: "relative", width: "100%" }}>
    {isLoading && (
      <MDBox
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(255, 255, 255, 0.1)", // semi-transparent overlay
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 9999, // Ensure it’s on top
        }}
      >
        <CircularProgress sx={{ color }} size={size} />
      </MDBox>
    )}
    <MDBox sx={{ opacity: isLoading ? 0.5 : 1 }}>{children}</MDBox> {/* Dim content when loading */}
  </MDBox>
);

LoadingOverlay.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
};

LoadingOverlay.defaultProps = {
  size: 60,
  color: "lightblue",
};

export default LoadingOverlay;
